<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          Importar Índices de Repasses
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon small @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row class="pt-5 mb-4">
          <v-btn
            color="info"
            class="mr-2"
            dark
            outlined
            href="/files/cities-indexes-import-example.csv"
            download
          >
            <v-icon left v-text="'mdi-download'" />
            Download arquivo de exemplo
          </v-btn>
        </v-row>
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="typeOfApportionmentId"
              >
                <v-select
                  v-model="fields.typeOfApportionmentId"
                  :items="typesOfApportionments"
                  item-value="id"
                  item-text="description"
                  label="Tipo"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="year">
                <v-text-field
                  v-model="fields.year"
                  v-mask="'####'"
                  label="Ano"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="file">
                <v-file-input
                  v-model="fields.file"
                  accept=".csv"
                  hide-details="auto"
                  label="Arquivo"
                  hint="Selecione o arquivo"
                  persistent-hint
                  prepend-icon=""
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>

        <v-row class="">
          <v-col>
            <validation-provider v-slot="{ errors }" vid="file">
              <v-checkbox
                v-model="fields.overwrite"
                :false-value="0"
                :true-value="1"
                color="warning"
                hide-details="auto"
                :error-messages="errors"
                class="mt-0"
              >
                <template v-slot:label>
                  <div>
                    <p class="subtitle-1 my-0">
                      Sobreescrever dados já existentes para o tipo e ano informado?
                    </p>
                    <p class="my-0 warning--text">
                      <small>
                       ⚠️ Marque somente se deseja substituir os valores atuais
                      </small>
                    </p>
                  </div>
                </template>
              </v-checkbox>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" outlined @click="showDialogConfirm = true">
          <v-icon left v-text="'mdi-import'" />
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showDialogConfirm" max-width="400px" persistent>
      <v-card>
        <v-toolbar flat dense dark color="warning">
          <v-toolbar-title>
            Confirme!
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              small
              @click="showDialogConfirm = false"
              :disabled="loadingImport"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-4 text-center">
          <p class="ma-0 black--text">
            Tem certeza que deseja importar esse arquivo?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            @click="showDialogConfirm = false"
            :disabled="loadingImport"
          >
            <v-icon left v-text="'mdi-cancel'" />
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :loading="loadingImport"
            :disabled="loadingImport"
            outlined
            @click="importFile"
          >
            <v-icon class="mr-2">
              mdi-import
            </v-icon>
            Sim, importar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import typesOfApportionmentsApi from '@/api/types-of-apportionments'
import citiesApi from '@/api/cities'
import toBase64 from '@/utils/to-base64'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDialogConfirm: false,
      loading: false,
      loadingImport: false,
      loadingImportProgress: 0,
      typesOfApportionments: [],
      fields: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  watch: {
    show(val) {
      if (!val) return

      this.load()
    },
  },

  methods: {
    async load() {
      try {
        this.loading = true
        this.typesOfApportionments = []
        const responseTypesOfApportionments = await typesOfApportionmentsApi.list()
        this.typesOfApportionments =
          responseTypesOfApportionments.data.typesOfApportionments
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async importFile() {
      try {
        this.loadingImport = true

        await citiesApi.importTypeOfApportionment({
          typeOfApportionmentId: this.fields.typeOfApportionmentId,
          year: this.fields.year,
          overwrite: this.fields.overwrite || 0,
          file: this.fields.file ? await toBase64(this.fields.file) : null,
        })

        this.fields = {}
        this.show = false
        this.$snackbar.show({ color: 'success', message: 'Dados importados com sucesso' })
      } catch (e) {
        console.log(e)
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingImport = false
        this.showDialogConfirm = false
      }
    },
  },
}
</script>
